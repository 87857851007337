
<template>
  <div style="width:100%">
      <Row :gutter="10" style="margin:3px 0 3px 0;text-align: center;" >
          <Col span="24" style="border-right: 0px solid black;" >
              <template >
                    <Tabs v-model="box_num" type="card">
                      <TabPane v-for="(item, index) in tab_box_nums" :key="index" :label="item.label" :name="item.name">
                          <Row>
                              <Col span="24" style="border-right: 0px solid black;" >
                                    <Card v-for="(item2, index2) in text_lives[item.name]" :key="index2" :bordered="true">
                                        <Row type='flex' justify='start' style='font-size:10px;'>
                                          <Col span='16' style="text-align: left;">
                                              <span class='text-main' v-if="parseInt( item2.box_num) <5">第{{item2.box_num}}节</span>
                                              <span class='text-main' v-else-if="item2.box_num==='5' ">加时</span>
                                              <span class='text-main' v-else> {{item2.box_num}} </span>
                                              <span class='text-main' >/ {{item2.time_left_str}}</span>
                                              <span class='text-main' >/ {{item2.home_score}}-{{item2.away_score}}</span>
                                          </Col>
                                        </Row>
                                        <Row type='flex' justify='start' style="background:rgb(244 255 243);"> <span class='text-main'>zh: {{item2.desc_zh}}</span></Row>
                                        <Row type='flex' justify='start' style="background:rgb(244 255 243);"> <span class='text-main'>en: {{item2.desc_en}}</span></Row>
                                    </Card>
                              </Col>
                          </Row>
                      </TabPane>
                    </Tabs>
              </template>
          </Col>
      </Row>
  </div>

</template>

<script>

import { GetTextlive, GetTabsList, } from '@/api/sports/match'
import Client from '@/utils/mqtt/mqtt-pro-new';

export default {
  data () {
    let self = (window.x = this)
    return {
      tab_box_nums :[{
        "name": "1",
        "label": "第1节",
      },{
        "name": "2",
        "label": "第2节",
      },{
        "name": "3",
        "label": "第3节",
      },{
        "name": "4",
        "label": "第4节",
      },],
      sport_id: 0,
      match_id: 0,
      box_num: '0',
      loading: true,
      data: {
      },
      // 文字直播
      text_lives: {
        "1": [],"2": [],"3": [],"4": [],
      },
      nami_text_lives: {
        "1": [],"2": [],"3": [],"4": [],
      },
    }
  },
  components: {
  },
  methods: {
    // 批量替换
    hookReplaceAll(){
      String.prototype.replaceAll = function(s1,s2){
        return this.replace(new RegExp(s1,"gm"),s2);
      }
    },

    // 获取文字直播
    apiGetLog(_type, box_num){
        let self = this;
        var params = {
            sport_id:self.sport_id,
            match_id: self.match_id,
            box_num: box_num,
            type: _type,
        }
        GetTextlive( params ).then(response => {
          if (response.data.code == 0) {
            // 重新获取文字直播
            if (_type === 2){
              // self.text_lives = response.data.data;
              for (var val in response.data.data){
                  self.$set(self.text_lives, val, response.data.data[val] );
                  // 当前局的直播列表 - 解决最后一条删除之后 数据依然存在的bug
              }
              // console.log(self.text_lives)

            }else{
              for (var val in response.data.data){
                  self.$set(self.nami_text_lives, val, response.data.data[val] );
              }
            }
          } else {
            this.$Message.error(response.data.msg)
          }
        })
    },
    //end
    // 长连接
    socketConnetc (topic) {
      let self = this;
      console.log("HHHHHHHH:",Client);
      self.client = new Client({
        topic: `${topic}`,
        message (t, msg) {
          if (msg.type === 4 ){
              let msgData = msg.data;
              // console.log(msgData);
              // 判断box-num是否需要更新
              var box_num = msgData.box_num || 0;

              var old_box_nums = [];
              for(var _index in self.tab_box_nums){
                  old_box_nums.push( self.tab_box_nums[_index].name );
              }
              if ( ! old_box_nums.includes( String( box_num ) )){
                  self.getTabsData();
              }
              else if (box_num ===0 || box_num === undefined){
                  self.getTabsData();
              }
              self.apiGetLog(2, box_num);
              if (box_num){
                self.box_num = String( box_num );
              }
          }
        },
        connect(){
          self.getTabsData();
          self.apiGetLog(2);
        },
      })
    },
    // end
    getTabsData () {
      let self = this;
      var params = {
        sport_id: self.sport_id,
        match_id: self.match_id,
      }
      GetTabsList(params).then(response => {
        if (response.data.code == 0) {
          var data = response.data.data;
          // // 生成小节数
          // var old_box_nums = []
          // for ( var _index in self.tab_box_nums){
          //     // console.log(tab)
          //     old_box_nums.push( self.tab_box_nums[_index].name );
          // }
          self.tab_box_nums = data.list;
        } else {
          self.$Message.error(response.data.msg)
        }
      })
    },

  },
  mounted () {
    let self = this;
    this.sport_id = this.$route.query.sport_id
    this.match_id = this.$route.query.match_id
    this.box_num = this.$route.query.box_num
    this.hookReplaceAll();
  },
  created () {
    this.sport_id = this.$route.query.sport_id
    this.match_id = this.$route.query.match_id
    var topic = "namicms/match_detail/"+ this.sport_id + "_" + this.match_id;
    this.socketConnetc(topic); //
  },
  watch: {
    // end
  },
  computed: {
  }
}
</script>

<style scoped>
  .team_name {
     font-size:10px
  }

  .ivu-form-item {
      margin-bottom: 0px;
      vertical-align: top;
      zoom: 1;
  }

  .ivu-table .demo-table-bing-row td:first-child{
      background-color: #2db7f5;
      color: #fff;
  }


</style>
