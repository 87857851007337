import http from '@/utils/http';

export const MatchDetail = (parameter) => {
  return http.request({
    url: '/v1/text_live/match/detail',
    method: 'get',
    params: parameter
  })
}

export const AddTextlive = (parameter) => {
  return http.request({
    url: '/v1/text_live/add',
    method: 'post',
    data: parameter
  })
}

export const GetTextlive = (parameter) => {
  return http.request({
    url: '/v1/text_live/get',
    method: 'get',
    params: parameter
  })
}

export const UpdateTextlive = (parameter) => {
  return http.request({
    url: '/v1/text_live/update',
    method: 'post',
    data: parameter
  })
}

export const DeleteTextlive = (parameter) => {
  return http.request({
    url: '/v1/text_live/delete',
    method: 'post',
    data: parameter
  })
}

export const DragTextlive = (parameter) => {
  return http.request({
    url: '/v1/text_live/drag',
    method: 'post',
    data: parameter
  })
}


export const ReplaceTextlive = (parameter) => {
  return http.request({
    url: '/v1/text_live/replace',
    method: 'post',
    data: parameter
  })
}

export const TransferTextlive = (parameter) => {
  return http.request({
    url: '/v1/text_live/transfer',
    method: 'post',
    data: parameter
  })
}

export const GetTabsList = (parameter) => {
  return http.request({
    url: '/v1/text_live/tabs/list',
    method: 'get',
    params: parameter
  })
}

export const DeleteLogTab = (parameter) => {
  return http.request({
    url: '/v1/text_live/tabs/delete',
    method: 'post',
    data: parameter
  })
}


export const MatchSources = (parameter) => {
  return http.request({
    url: '/v1/text_live/source/get',
    method: 'get',
    params: parameter
  })
}

export const updateMatchSource = (parameter) => {
  return http.request({
    url: '/v1/text_live/source/update',
    method: 'post',
    data: parameter
  })
}

export const CompetitionList = (parameter) => {
  return http.request({
    url: '/v1/text_live/competition/list',
    method: 'get',
    params: parameter
  })
}